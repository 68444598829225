<template>
  <div>
    <ShiftsDirectOfferTable actionType="accept" />
  </div>
</template>

<script>
import ShiftsDirectOfferTable from "@employer/ShiftsDirectOfferTable";

export default {
  components: {
    ShiftsDirectOfferTable,
  },
};
</script>
